import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Head from "../components/head"
import Breadcrumb from "../components/breadcrumb"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the pageCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their pagecss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import * as styles from "./page.module.scss"

const PageTemplate = ({ data: { previous, next, page } }) => {
  return (
    <Layout>
      <Head title={page.title} description={page.excerpt} />
      <div className={"section " + styles.section}>
        <div className="container is-max-desktop">
          <Breadcrumb
            datas={[
              {
                link: "",
                name: parse(page.title),
              },
            ]}
          />
          <article
            className="page"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header className="my-6">
              <h1 className={styles.title} itemProp="headline">
                {parse(page.title)}
              </h1>
            </header>
            {!!page.content && (
              <section itemProp="articleBody">
                <div className="content">{parse(page.content)}</div>
              </section>
            )}
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query WpPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPageId: String
    $nextPageId: String
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "YYYY.MM.DD")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    # this gets us the previous post by id (if it exists)
    previous: wpPage(id: { eq: $previousPageId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPage(id: { eq: $nextPageId }) {
      uri
      title
    }
  }
`
